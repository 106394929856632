import { graphql } from 'gatsby'
import React from 'react'
import Intro from '../components/Intro/Intro'
import Layout from '../components/Layout'
import Post from '../components/Post'
import { SEO } from '../components/SEO'
import { generateJsonLd } from '../components/util'

class IndexRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges.map(post => (
      <Post data={post} key={post.node.fields.slug} />
    ))

    return (
      <Layout>
        <React.Fragment>
          <SEO pageTitle="Blog">
            <script type="application/ld+json">
              {generateJsonLd('Blog', 'On this blog, I share my experiences and knowledge in web development, covering topics like Angular, React, and TypeScript. With over 10 years in software engineering—progressing from software engineer to senior, lead, and now engineering manager—I aim to offer insights that might give you a fresh perspective on building modern web applications', 'https://trungvose.com/')}
            </script>
          </SEO>
          <Intro data={this.props.data} />
          <div className="content">
            <div className="content__inner">{posts}</div>
          </div>
        </React.Fragment>
      </Layout>
    )
  }
}

export default IndexRoute

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        author {
          name
          email
          twitter
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      filter: { frontmatter: { layout: { eq: "post" }, draft: { ne: true } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            description
          }
        }
      }
    }
  }
`
