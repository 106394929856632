import React from 'react';
import logoLight from '../../assets/images/trungk18-no-text.png';
import logoDark from '../../assets/images/trungk18-no-text-dark.png'
import './intro.scss'
import SocialLinks from './SocialLinks'

export default function Intro({ data }) {
  const { subtitle } = data.site.siteMetadata

  return (
    <div className="intro-container">
      <div className="intro-content">
        <img alt="" className="intro-image intro-image-light" src={logoLight} />
        <img alt="" className="intro-image intro-image-dark" src={logoDark} />
        <h1 className="intro-title">Hello, I'm Trung Vo</h1>
        <p className="intro-sub-title">{subtitle}</p>
        <SocialLinks />
        <p className="intro-copyright">
          Made with <span className="made-with-love">❤</span> in Singapore
        </p>
      </div>
    </div>
  )
}
